@import "vars"

=font-face($family, $pathname, $type, $weight: normal, $style: normal)
  @font-face
    font-family: $family
    src: url('../assets/fonts/#{ $pathname }-#{ $type }.eot'), url('../assets/fonts/#{ $pathname }-#{ $type }.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/#{ $pathname }-#{ $type }.woff') format('woff'), url('../assets/fonts/#{ $pathname }-#{ $type }.woff2') format('woff2'), url('../assets/fonts/#{ $pathname }-#{ $type }.ttf') format('truetype')
    font-weight: $weight
    font-style: $style
    font-display: swap

+font-face("Avenir", 'Avenir', 'Light', 300)
+font-face("Avenir", 'Avenir', 'Book', 400)
+font-face("Avenir", 'Avenir', 'Medium', 500)
+font-face("Avenir", 'Avenir', 'Black', 700)
