@import "vars"
@import "fonts"

html, body
  font-family: $fontMain
  font-size: .966vw

  @include mobile
    font-size: 2.8vw

*
  font-family: $fontMain
  color: $dark

h1
  font-size: 3.2rem
  line-height: 3.7rem

/*30*/
h2
  font-size: 2rem
  line-height: 2.5rem

  /*25*/
  &.small
    font-size: 1.65rem
    line-height: 2rem

/*20*/
h3
  font-size: 1.3rem
  line-height: 1.7rem


/*19*/
h4
  font-size: 1.25rem
  line-height: 1.55rem

  /*18*/
  &.small
    font-size: 1.15rem
    line-height: 1.4rem

/*15*/
a, p, span
  font-size: .95rem
  line-height: 1.25rem

  /*16*/
  &.large
    font-size: 1.05rem
    line-height: 1.3rem


  /*10*/
  &.small
    font-size: .7rem
    line-height: 1rem


h1, h2, h3, h4, a, p, span
  font-weight: 700

h1, h2, h3, h4, p, a, span
  &.fontMain
    font-family: $fontMain

  &.italic
    font-style: italic

  &.lh-normal
    line-height: normal

  &.thin
    font-weight: 100

  &.extraLight
    font-weight: 200

  &.light
    font-weight: 300

  &.regular
    font-weight: 400

  &.medium
    font-weight: 500

  &.semiBold
    font-weight: 600

  &.bold
    font-weight: 700

  &.extraBold
    font-weight: 900

  &.ls-1
    letter-spacing: .08rem

  &.ls-2
    letter-spacing: .11rem

  &.uppercase
    text-transform: uppercase

  &.underline
    text-decoration: underline

  &.dark
    color: $dark

  &.dark-2
    color: $dark-2

  &.black
    color: $black

  &.white
    color: $white

  &.grey
    color: $grey

  &.blue
    color: $blue

  &.darkBlue
    color: $darkBlue

  &.red
    color: $red

  &.lightBlue
    color: $lightBlue

  &.green
    color: $green

  &.darkGreen
    color: $darkGreen



