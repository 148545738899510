@import "vars"
@import "typography"

html, body
  width: 100vw
  height: 100%
  margin: 0
  background-color: $white

body
  overflow-x: hidden
  overflow-y: scroll
  -webkit-overflow-scrolling: touch
  min-height: -webkit-fill-available

#Modal
  position: fixed
  z-index: 9
  top: 0
  left: 0
  width: 100%
  height: 0

  &.open
    height: 100%


// reusable classes

.display-none
  display: none

.display-inline
  display: inline-block

.box-sizing
  box-sizing: border-box

.cursor-pointer
  user-select: none
  cursor: pointer

.cursor-info
  user-select: none
  cursor: help

.no-pointer-events
  pointer-events: none

.no-select
  user-select: none

.overflow-hidden
  overflow: hidden

.scrollable-x
  overflow-x: auto
  overflow-y: hidden
  -webkit-overflow-scrolling: touch


.invisible
  opacity: 0

.scrollable-y
  overflow-x: hidden
  overflow-y: auto
  -webkit-overflow-scrolling: touch

.scrollbar-hidden
  -ms-overflow-style: none
  scrollbar-width: none

  &::-webkit-scrollbar
    display: none

// hover/active effects

@include desktop
  .hover-opacity
    transition: .2s

    &:hover
      opacity: .7
      transition: .2s

    &:active
      opacity: .6
      transition: .2s

  .hover
    transition: .2s

    &:hover
      opacity: .7
      transition: .2s

    &:active
      transform: scale(.95)
      transition: .2s



// text

.text-left
  text-align: left

.text-center
  text-align: center

.text-right
  text-align: right

.no-wrap
  white-space: no-wrap

.pre-line
  white-space: pre-line

.word-break-word
  word-break: break-word

.limit-string-1
  width: 100%
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

@for $i from 2 through 10
  .limit-string-#{ $i }
    width: 100%
    white-space: pre-line
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: $i
    -webkit-box-orient: vertical



// images

img
  &.covered
    width: 100%
    height: 100%
    object-fit: cover
    display: flex

  &.contained
    width: 100%
    height: 100%
    object-fit: contain
    display: flex

  &.filled
    width: 100%
    height: 100%
    object-fit: fill
    display: flex

  &.fullWidth
    width: 100%
    height: auto
    display: flex

  &.fullHeight
    width: auto
    height: 100%
    display: flex



// Animations

@keyframes spinner
  from
    transform: rotate(0)
  to
    transform: rotate(360deg)


// Margins & Paddings

.mv-05
  margin-top: .5rem
  margin-bottom: .5rem

.mt-05
  margin-top: .5rem

.mb-05
  margin-bottom: .5rem

.mh-05
  margin-left: .5rem
  margin-right: .5rem

.ml-05
  margin-left: .5rem

.mr-05
  margin-right: .5rem

.pv-05
  padding-top: .5rem
  padding-bottom: .5rem

.pt-05
  padding-top: .5rem

.pb-05
  padding-bottom: .5rem

.ph-05
  padding-left: .5rem
  padding-right: .5rem

.pl-05
  padding-left: .5rem

.pr-05
  padding-right: .5rem

@for $i from 0 through 30
  .m-#{ $i }
    margin: 1rem * $i

  .p-#{ $i }
    padding: 1rem * $i

  .mv-#{ $i }
    margin-top: 1rem * $i
    margin-bottom: 1rem * $i

  .pv-#{ $i }
    padding-top: 1rem * $i
    padding-bottom: 1rem * $i

  .mh-#{ $i }
    margin-left: 1rem * $i
    margin-right: 1rem * $i

  .ph-#{ $i }
    padding-left: 1rem * $i
    padding-right: 1rem * $i

  .mt-#{ $i }
    margin-top: 1rem * $i

  .pt-#{ $i }
    padding-top: 1rem * $i

  .mb-#{ $i }
    margin-bottom: 1rem * $i

  .pb-#{ $i }
    padding-bottom: 1rem * $i

  .ml-#{ $i }
    margin-left: 1rem * $i

  .pl-#{ $i }
    padding-left: 1rem * $i

  .mr-#{ $i }
    margin-right: 1rem * $i

  .pr-#{ $i }
    padding-right: 1rem * $i



// Other

.no-desktop
  @include desktop
    display: none !important

.no-mobile
  @include mobile
    display: none !important

@for $i from 0 through 10
  .opacity-#{ $i }
    opacity: 0.1 * $i

//.shadow
//  box-shadow: 0 .25rem 1.4rem rgba($dark-2, 0.1)
//
//@for $i from 2 through 9
//  .shadow-#{ $i }
//    box-shadow: 0 .25rem 1.4rem rgba($dark-2, 0.1 * $i)

a
  color: unset
  text-decoration: unset

//Button

button
  font-size: 1.05rem
  line-height: 1.25rem
  background: $red
  outline: none !important
  border: none !important
  border-radius: .55rem
  font-weight: 700
  color: $white
  letter-spacing: .05rem
  padding: 1.1rem 1.9rem
  box-sizing: border-box
  font-family: $fontMain
  cursor: pointer
  transition: .2s

  &:hover
    opacity: .8
    transition: .2s

  &:active
    opacity: .7
    transform: scale(.95)
    transition: .2s


  &.green
    background: $green
    color: $darkGreen

  &.blue
    background: $blue
    color: $white


//Input

input
  width: 100%
  font-size: 1.05rem
  line-height: 1.25rem
  outline: none
  border: none
  border-radius: .55rem
  font-weight: 400
  font-family: $fontMain
  color: $dark
  padding: 1.1rem 1.5rem
  box-sizing: border-box

  &.italic
    font-style: italic

  &::placeholder
    color: $grey

  &.border
    border: 1px solid $grey

  &.error
    border-color: $red

  &.checkbox
    width: 1rem
    height: 1rem
    border-color: $grey-5
    border-radius: calc(#{$elementsRadius} * 2)


