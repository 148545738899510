// Fonts

$fontMain: 'Avenir', sans-serif

// Colors

$black: #000
$white: #FFF

$dark: #313233
$dark-2: #1D1D1B

$grey: #ADADAD
$grey-2: #D3D3D3
$grey-3: #EFEFEF
$grey-4: #707070
$grey-5: #909090
$lightGrey: #F4F4F4

$blue: #54C2EC
$blue-2: #E3F7FF
$lightBlue: #DDF3FB
$darkBlue: #003B52

$red: #E30D1D

$green: #3DDC97
$darkGreen: #004829


// Markup

$headerHeight: 6.7rem
$mobileHeaderHeight: 6.7rem
$desktopMinHeight: 580px

$contentHorizontalPadding: 11rem
$contentHorizontalPaddingMobile: 1rem

$elementsRadius: .65rem
$buttonsRadius: 2rem
$mobileButtonsRadius: 3rem



// Other

$desktopSmall: 1160px
$tablet: 960px
$mobile: 767px
$mobileMini: 375px
$mobileLowHeight: 700px

$opacity: .7


// Mixins
@mixin mobile-mini
  @media (max-width: #{ $mobileMini})
    @content

@mixin mobile
  @media (max-width: #{ $mobile })
    @content

@mixin mobileLow
  @media (max-width: #{ $mobile }) and (max-height: #{ $mobileLowHeight })
    @content

@mixin mobile-tablet
  @media (min-width: 0) and (max-width: #{ $tablet })
    @content

@mixin tablet
  @media (min-width: #{ $mobile + 1px }) and (max-width: #{ $tablet })
    @content

@mixin tablet-desktop
  @media (min-width: #{ $mobile + 1px })
    @content

@mixin desktopSmall
  @media (min-width: #{ $tablet + 1px }) and (max-width: #{ $desktopSmall })
    @content

@mixin desktop
  @media (min-width: #{ $mobile })
    @content
